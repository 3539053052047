import {Component, HostBinding, HostListener} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('scroll', [
      // ...
      state('top', style({
        // height: '200px',
        opacity: 1,
        backgroundColor: 'transparent'
      })),
      state('scrolled', style({
        // height: '100px',
        opacity: 0.5,
        backgroundColor: 'rgba(234, 229, 209, 0.9)'
      })),
      transition('top => scrolled', [
        animate('1s')
      ]),
      transition('scrolled => top', [
        animate('1s')
      ]),
    ]),
  ]
})
export class AppComponent {
  title = 'Kopi Date | Strangers over Coffee';
  isHome = true;
  atTop = true;


  numDates = 0;
  numMatches = 0;
  numMins = 0;

  numDatesMax = 135;
  numMatchMax = 21;
  numMinMax = 113;
  // In Ms
  numMiliSec = 2000;

  intervalNumDates;
  intervalNumMatches;
  intervalNumMins;

  goToForm() {
    window.location.href = 'http://bit.ly/kopidatedec19'; //old https://goo.gl/forms/4MLWMZDDhYnGHk213
  }

  toogleIsHome() {
    this.isHome = !this.isHome;
    if (!this.isHome) {
      this.numDates = 0;
      this.numMatches = 0;
      this.numMins = 0;


      this.intervalNumDates = setInterval(() => {
        this.numDates++;
        if (this.numDatesMax <= this.numDates) {
          clearInterval(this.intervalNumDates);
        }

      }, this.numMiliSec / this.numDatesMax);

      this.intervalNumMatches = setInterval(() => {
        this.numMatches++;
        if (this.numMatchMax <= this.numMatches) {
          clearInterval(this.intervalNumMatches);
        }

      }, this.numMiliSec / this.numMatchMax);

      this.intervalNumMins = setInterval(() => {
        this.numMins++;
        if (this.numMinMax <= this.numMins) {
          clearInterval(this.intervalNumMins);
        }

      }, this.numMiliSec / this.numMinMax);
    } else {
      clearInterval(this.intervalNumDates);
      clearInterval(this.intervalNumMatches);
      clearInterval(this.intervalNumMins);

    }
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    // console.debug("Scroll Event", document.body.scrollTop);
    // see András Szepesházi's comment below
    console.log('Scroll Event', window.pageYOffset);
    if (window.pageYOffset) {
      this.atTop = false;
    } else {
      this.atTop = true;
    }
  }

  constructor(
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Dating, Kopi Date, Kopi Dates, Kopidate, coffee dates, Tinder, CMB, Dating Apps' },
      { name: 'robots', content: 'max-snippet:-1, max-image-preview:large, max-video-preview:-1' },
      { name: 'author', content: 'Zhiqun, Jinglin' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { name: 'description', content: 'Refreshing the dating scene one coffee at a time. Grab coffee now ☕'
      },
      {name:'twitter:card', content:'summary_large_image'},
      {name:'twitter:description', content:'Refreshing the dating scene one coffee at a time. Grab coffee now ☕'
    },
      {name:'twitter:title', content:'Kopi Date | Strangers over coffee'},
      {property:'og:locale', content:'en_US'},
      {property:'og:type', content:'website'},
      {property:'og:title', content:'Kopi Date | Strangers over coffee'},
      {property:'og:description', content:'Refreshing the dating scene one coffee at a time. Grab coffee now ☕ '},
      { charset: 'UTF-8' }
    ]);
    
  }
}
